$(document).ready(function () {
    $(".accordion-body").click(function () {
        $('input[type=checkbox]:checked').each(function () {
            var showName = this.value;
            var name = this.name;
            var markup = `<button type="button"  name=${name} class="dynamic-button"> ${showName}</button>`;
            console.log(markup)
            if (name != '') {
                const exists = Array.from(
                    document.querySelectorAll(
                        '.dynamic > button'
                    )).some(input => input.name == name);
                if (exists) {
                    return;
                } else {
                    $('.dynamic').append(markup);
                }
            } 
        })
    });
    $('input[type=checkbox]').on('click', function(e){
        $('.dynamic-button').each(function () {
            var name = this.name;
            if (e.target.name == name) {
                $(this).remove();
            }
    
        })
    })
});
//
// $(document).on('click', 'input[type=checkbox]', function(e){
//     console.log(e.target.name);
//     $('.dynamic-button').each(function () {
//         var name = this.name;
//         console.log(name);
//         if (e.target.name == name) {
//             $(this).remove();
//         }

//     })
       
              
//   });