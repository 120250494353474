
$(".filter-toggle-btn").on("click", function () {
    $(".filter-result").toggleClass("hide-result");
});

$(".filter-close-btn").on("click", function () {
    $(".filter-result").toggleClass("hide-result");
});

//adds mobile filter layout when screen size is lower than 500px
if ($(window).width() < 500) {
    $('.filter-mobile-hide').addClass('filter-mobile-show filter-result');
}
else if ($(window).width() > 500) {
    $('.filter-mobile-hide').removeClass('filter-mobile-show filter-result');
}

$(window).on('resize', function () {
    if ($(window).width() < 500) {
        $('.filter-mobile-hide').addClass('filter-mobile-show filter-result');
    }
});

$(window).on('resize', function () {
    if ($(window).width() > 500) {
        $('.filter-mobile-hide').removeClass('filter-mobile-show filter-result');
    }
});

//removes mobile filter layout when screen size is greater than 500px
$(window).on('resize', function () {
    if ($(window).width() > 500) {
        $('.filter-mobile-hide').removeClass('filter-mobile-show');
    }
});

//removes desktop filter layout when screen size is less than 500px

if ($(window).width() < 500) {
    $('.filter-desktop').addClass('filter-desktop-hide').removeClass("filter-result");
}
else if ($(window).width() > 500) {
    $('.filter-desktop').removeClass('filter-desktop-hide').addClass("filter-result");
}

$(window).on('resize', function () {
    if ($(window).width() < 500) {
        $('.filter-desktop').addClass('filter-desktop-hide').removeClass("filter-result");
    }
});

//adds desktop filter layout when screen size is greater than 500px

$(window).on('resize', function () {
    if ($(window).width() > 500) {
        $('.filter-desktop').removeClass('filter-desktop-hide').addClass("filter-result");
    }
});




$(document).ready(function () {
    $("body").removeClass("plone-toolbar-left").removeClass("plone-toolbar-left-expanded")

    if ($("body.userrole-authenticated li#contentview-edit > a").children("svg").length == 0) {
        $("body.userrole-authenticated li#contentview-edit > a").append(`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="plone-icon me-1 bi bi-pencil" viewBox="0 0 16 16">
    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"></path>
  </svg>`)
    }
    if ($("body.userrole-authenticated li#contentview-view > a").children("svg").length == 0) {
        $("body.userrole-authenticated li#contentview-view > a").append(`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="plone-icon me-1 bi bi-eye" viewBox="0 0 16 16">
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
</svg>`)
    }

})


$('#agreement-button').click(function() {
    $('#privacy-statement1').remove();
    $('.documentDescription').remove();
    $('#responsive-image').remove();
    $('#op-ed-content').remove();
    $('#qualtrics-area').append('');
});
// Scroll page to top
//
// On Qualtrics (https://kmrc.qualtrics.com) add JS to every
// survey page and write:
//
// parent.postMessage("scroll", "https://www.e-teaching.org");
//
window.addEventListener("message", receiveMessage);
function receiveMessage(event) {
    if (event.origin !== "https://kmrc.qualtrics.com")
    return;
    if (event.data !== "scroll")
    return;
    var temp = parseInt(event.data) + 0;
    $("html,body").scrollTop(0);responsive
}

$("#collapsible-btn").click(function(){
    $('.plus-sign').toggleClass('hide-icon');
    $('.minus-sign').toggleClass('hide-icon');
})

//code for the portlet image links on the start page
$(".gallery").click(function(e) {
    var link = $(e.currentTarget).find('a')
    window.open(link.attr('href'), link.attr('target'));
})

$(".gallery").hover(function() { 
    $('.gallery').css('cursor', 'pointer');
});

