if ($(window).width() < 500) {
   
    $('.viewlet-image-right').addClass('full-width').removeClass('right');
}
else if ($(window).width() > 500) {
    $('.viewlet-image-right').removeClass('full-width').addClass('right');
}

$(window).on('resize', function () {
    
    if ($(window).width() < 500) {
        $('.viewlet-image-right').addClass('full-width').removeClass('right');
    }
    else if ($(window).width() > 500) {
        $('.viewlet-image-right').addClass('right').removeClass('full-width');
    }
});

if ($(window).width() < 500) {
   
    $('.viewlet-image-left').addClass('full-width').removeClass('left');
}
else if ($(window).width() > 500) {
    $('.viewlet-image-left').removeClass('full-width').addClass('left');
}

$(window).on('resize', function () {
    
    if ($(window).width() < 500) {
        $('.viewlet-image-left').addClass('full-width').removeClass('left');
    }
    else if ($(window).width() > 500) {
        $('.viewlet-image-left').addClass('left').removeClass('full-width');
    }
});