$('tbody.showmore-table').each(function() {
    var list = $(this).children().filter("tr");
    var max = 10
    console.log(list)
    var showMoreBtn = document.getElementsByClassName('show-more')[0];
  
    $.each(list, function(i, value) {
      if (i > max) $(this).hide();
    });
    console.log(list.length, max)
    if (list.length > max) {
      $(this).find(".show-more").show();
    }
    else {
        showMoreBtn.style.display = 'none';
    }

    $(".show-more").click(function () {
        max += 10
        $.each(list, function(i, value) {
            if (i < max) $(this).show();
          });
        if (list.length <= max) {
            showMoreBtn.style.display = 'none';
        }
      });      
});