$(document).ready(function () {

  var portalUrl = $("body").attr("data-portal-url");
  var $menuList = $("#menu ul.nav-list");
  var _menu = $("#menu");
  var _icon = $("#toggle-button .fa");

  async function fetchNavJSON() {
    const response = await fetch(`${portalUrl}/@navigation?expand.navigation.depth=5`, {
      headers: {
        Accept: "application/json"
      }
    })

    const Navi = await response.json();
    return Navi;

  };

  fetchNavJSON().then(res => {

    //handle data

    var data = res
    var getMenuItem = function (data) {
      var item = $(`<li class="nav-item" title="${data.title}">`).append(
        $("<a>", {
          href: data["@id"],
          html: data["title"],
          class: "nav-link"
        })
      );
      if (data.items.length > 0) {
        var subList = $(`<ul class='dropdown-content'><h2 class="level-title">${data.title}</h2>
          <li title=${data.title}><a class="nav-link" href=${data['@id']}>Übersicht</a></li>
          </ul>`);
        $.each(data.items, function () {
          subList.append(getMenuItem(this));
        });
        item.append(subList);
      }
      return item;
    };

    $.each(data.items, function (obj) {
      $menuList.append(getMenuItem(this));
    });
    // Base expand


  }).then(() => {

    $("#toggle-button").click(function () {
      $("#menu").multilevelpushmenu("expand");
    });

    $("#toggle-button-nav").click(function () {
      $("#menu").multilevelpushmenu("expand");
    });


    $("#menu").multilevelpushmenu({
      menuWidth: 500,
      backItemIcon: "fa fa-angle-left",
      groupIcon: "fa fa-angle-left",
      collapsed: true,
      wrapperClass: "mlpm_w",
      menuInactiveClass: "mlpm_inactive",
      preventItemClick: false,
      menuInactiveClass: 'multilevelpushmenu_inactive',
      backItemClass: 'backItemClass',
    });

    $('.close-button').on('click touchstart', function () {
      $("#menu").multilevelpushmenu("collapse");
    });


    $(window).on('resize', function () {
      $('#menu').multilevelpushmenu('redraw');
    });

    $("#menu a.nav-link").on('click', function (e) {
      $('.nav-link').removeClass('disabled');
      let hrefA = $(this)
      if ($(this).siblings(".levelHolderClass").length == 0) {
        let hrefAttrA = hrefA.attr("href");
        window.location.href = hrefAttrA;
      }
    })

  })
    .catch(error => {
      //handle error
    });





  $(document).on("click", function (event) {
    var $trigger = $("#menu");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
      $('#menu').multilevelpushmenu('collapse');
    }
  });

 

})


const menu = document.getElementById('menu');

if (menu && menu.style.width === '366192px' &&
    menu.style.height === '28322px' &&
    menu.style.minHeight === '28322px' &&
    menu.style.minWidth === '366192px') {
  menu.parentNode.removeChild(menu);
}





$(document).ready(function () {

  window.onscroll = function () { scrollFunction() };
  function scrollFunction() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      $("#portal-logo").css("transform", "translateX(75px)");
      $("#fade").removeClass('fade-out')
      $("#fade").addClass('fade-in')
      $(".portalheader-personalbar").addClass('fade-out')
      $(".portalheader-personalbar").removeClass('fade-in')

    } else {
      $("#portal-logo").css("transform", "translateX(0px)");
      $("#fade").removeClass('fade-in')
      $("#fade").addClass('fade-out')
      $(".portalheader-personalbar").removeClass('fade-out')
      $(".portalheader-personalbar").addClass('fade-in')
    }
  }
})

// here is the JS for the menu
$(document).ready(function () {
  //find div
  var div = $('div.showcase');
  var liNum = $(div).find('ul').children('li').length;

  var speed = 300;

  var containerWidth = document.getElementById('showcase').offsetWidth;
  var ulWidth = 0;
  $("li.linav").each(function () { ulWidth += jQuery(this).outerWidth() + 42; });

  var itemWidth = 120;
  //Remove scrollbars
  $(div).css({ overflow: 'hidden' });
  $('button.right-arrow').click(function (e) {
    if ((ulWidth > $(div).scrollLeft() + containerWidth)) {
      $("#left-arrow").removeClass("disabled");

      if (ulWidth < $(div).scrollLeft() + containerWidth + itemWidth) {
        $(div).animate({
          scrollLeft: '+=' + (ulWidth - containerWidth - $(div).scrollLeft())
        }, speed);
        $("#right-arrow").addClass("disabled");
      }

      else {
        $(div).animate({
          scrollLeft: '+=' + itemWidth
        }, speed);
      }

    }
  });
  $('button.left-arrow').click(function (e) {
    if (($(div).scrollLeft() - itemWidth) <= 0) { $("#left-arrow").addClass("disabled") }
    if (($(div).scrollLeft() + containerWidth) > containerWidth) {
      $("#right-arrow").removeClass("disabled");
      $(div).animate({
        scrollLeft: '-=' + itemWidth
      }, speed)
    }
  });
});


